<template>
  <div>
    <v-row>
      <v-col>
        <tiptap-vuetify
          v-model="policyContent"
          language="en"
          :extensions="extensions"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-btn
          color="primary"
          @click="savePolicy"
        >
          Save Policy
        </v-btn>
        <v-btn
          class="ml-3"
          color="primary"
          @click="$emit('cancel')"
        >
          Cancel
        </v-btn>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import { createPolicy, updatePolicy } from '@/shared/apis/policyApi';

import {
  TiptapVuetify,
  Heading,
  Bold,
  Italic,
  Strike,
  Underline,
  BulletList,
  OrderedList,
  ListItem,
  HardBreak,
} from 'tiptap-vuetify';

export default {
  name: 'PolicyEditor',
  components: { TiptapVuetify },
  emits: ['input'],
  props: {
    policy: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      extensions: [
        Underline,
        Strike,
        Italic,
        ListItem,
        BulletList,
        OrderedList,
        [Heading, {
          options: {
            levels: [1, 2, 3],
          },
        }],
        Bold,
        HardBreak,
      ],
      policyContent: '',
    };
  },
  created() {
    if (this.policy) {
      this.policyContent = this.policy.content;
    }
  },
  methods: {
    async savePolicy() {
      let result;

      if (this.policy) {
        result = await updatePolicy(this.policy.id, {
          content: this.policyContent,
        });
      } else {
        result = await createPolicy({
          content: this.policyContent,
        });
      }
      this.$emit('save', result);
    },
  },
};
</script>
<style lang="scss" scoped>
</style>

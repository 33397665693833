import { HttpHelper } from '../helpers/httpHelper';

export async function getPolicies() {
  const response = await HttpHelper.get('/api/v1/policies');
  return response.data;
}

export async function getPolicy(id) {
  const response = await HttpHelper.get(`/api/v1/policies/${id}`);
  return response.data;
}

export async function createPolicy(data) {
  const response = await HttpHelper.post('/api/v1/policies', data);
  return response.data;
}

export async function updatePolicy(id, data) {
  const response = await HttpHelper.put(`/api/v1/policies/${id}`, data);
  return response.data;
}

export async function deletePolicy(id) {
  const response = await HttpHelper.delete(`/api/v1/policies/${id}`);
  return response.data;
}

export async function movePolicyUp(id) {
  const response = await HttpHelper.put(`/api/v1/policies/move_up/${id}`);
  return response.data;
}

export async function movePolicyDown(id) {
  const response = await HttpHelper.put(`/api/v1/policies/move_down/${id}`);
  return response.data;
}

<!-- eslint-disable vue/no-v-html -->
<template>
  <v-container fluid>
    <v-layout column>
      <v-card
        class="mb-3"
      >
        <v-card-title>
          <h1>Policies</h1>
          <v-spacer />
          <v-btn
            v-if="!showPolicyEditor && !policyToEdit"
            color="primary"
            :disabled="showPolicyEditor || policyToEdit"
            @click="addPolicy"
          >
            Add Policy
          </v-btn>
        </v-card-title>
        <v-card-text>
          <policy-editor
            v-if="showPolicyEditor"
            @save="onPolicySaved"
            @cancel="showPolicyEditor = false"
          />
        </v-card-text>
      </v-card>
      <v-card
        v-for="(policy, index) in policies"
        :key="policy.id"
        class="mb-3"
      >
        <v-card-text
          v-if="policyToEdit !== policy"
          class="d-flex justify-space-between"
        >
          <div v-html="policy.content" />
          <div
            v-if="user.isSuperAdmin"
            class="d-flex align-start justify-end"
            style="min-width: 100px;"
          >
            <v-btn
              v-if="index !== 0"
              icon
              @click="movePolicyUp(policy)"
            >
              <v-icon>
                mdi-arrow-up-thick
              </v-icon>
            </v-btn>

            <v-btn
              v-if="index !== policies.length - 1"
              icon
              @click="movePolicyDown(policy)"
            >
              <v-icon>
                mdi-arrow-down-thick
              </v-icon>
            </v-btn>

            <v-btn
              icon
            >
              <v-icon
                @click="editPolicy(policy)"
              >
                mdi-pencil
              </v-icon>
            </v-btn>
            <v-btn
              icon
              @click="deletePolicy(policy)"
            >
              <v-icon>mdi-delete</v-icon>
            </v-btn>
          </div>
        </v-card-text>
        <v-card-text
          v-if="policyToEdit === policy"
        >
          <policy-editor
            :policy="policy"
            @save="onPolicySaved"
            @cancel="policyToEdit = null"
          />
        </v-card-text>
      </v-card>
    </v-layout>
  </v-container>
</template>
<script>
import userMixin from '@/components/mixins/userMixin';
import PolicyEditor from '@/components/PolicyEditor.vue';
import {
  getPolicies, deletePolicy, movePolicyUp, movePolicyDown,
} from '@/shared/apis/policyApi';

import ConfirmDialog from '@/components/elements/ConfirmDialog.vue';
import { create } from 'vue-modal-dialogs';

const confirmDialog = create(ConfirmDialog);

export default {
  name: 'Policies',
  components: {
    PolicyEditor,
  },
  mixins: [userMixin],
  data() {
    return {
      showPolicyEditor: false,
      policyToEdit: null,
      policies: [],
    };
  },
  async created() {
    await this.getPolicies();
  },
  methods: {
    async getPolicies() {
      this.policies = await getPolicies();
    },
    addPolicy() {
      this.showPolicyEditor = true;
    },
    editPolicy(policy) {
      this.policyToEdit = policy;
    },
    async deletePolicy(policy) {
      const result = await confirmDialog({
        title: 'Are you sure?',
        content: 'Are you sure you want to delete this policy?',
        confirm: 'Yes',
        cancel: 'No',
        swapButtonColors: true,
      });

      if (!result) return;

      await deletePolicy(policy.id);
      await this.getPolicies();
    },
    async movePolicyUp(policy) {
      await movePolicyUp(policy.id);
      await this.getPolicies();
    },
    async movePolicyDown(policy) {
      await movePolicyDown(policy.id);
      await this.getPolicies();
    },
    onPolicySaved(result) {
      this.showPolicyEditor = false;
      this.policyToEdit = null;

      // find policy with same id and replace it
      const index = this.policies.findIndex((policy) => policy.id === result.id);
      if (index !== -1) {
        this.policies.splice(index, 1, result);
      } else {
        this.policies.push(result);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
</style>
